<template>
  <v-app>
    <core-toolbar />

    <!-- <core-drawer /> -->

    <core-view />

    <core-footer />


    <!-- <v-main>
      <router-view/>
    </v-main> -->
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
    // CoreDrawer: () => import('@/components/core/Drawer'),
    CoreFooter: () => import('@/components/core/Footer'),
    CoreToolbar: () => import('@/components/core/Toolbar'),
    CoreView: () => import('@/components/core/View')
  },

};
</script>
<style >
  .hide-button {
    display: fixed;
  }
</style>
